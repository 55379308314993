import React, { useState } from 'react'
import cs from 'classnames'
import { CSSTransition } from 'react-transition-group'
import Link from '../Link'
import styles from './OverlayMenus.module.scss'

const OverlayMenus = ({ menus = [], onClose = f => f, activeMenuIndex }) => {
  const isActive =
    activeMenuIndex !== null && menus[activeMenuIndex].child_items

  const [activeMenuItemid, setActiveMenuItemId] = useState(null)
  return (
    <div className={cs(styles.root, { [styles.active]: isActive })}>
      <div
        className={cs(
          styles.inner,
          'header-padding-offset primary-logo-padding-offset flex items-stretch'
        )}
      >
        <div className={cs(styles.contentContainer, 'flex-auto')}>
          {menus.map(
            ({ child_items, object_id }, i) =>
              child_items && (
                <ul
                  key={object_id}
                  className={cs(styles.menu, {
                    [styles.active]: i === activeMenuIndex,
                    'in-view': i === activeMenuIndex,
                  })}
                >
                  {child_items.map(({ object_id, title, url, target }, i) => (
                    <li
                      key={object_id}
                      className="primary-appearance"
                      style={{ transitionDelay: `${i * 100 + 300}ms` }}
                    >
                      <Link
                        to={url}
                        onClick={onClose}
                        onFocus={() => setActiveMenuItemId(object_id)}
                        onBlur={() => setActiveMenuItemId(null)}
                        onMouseOver={() => setActiveMenuItemId(object_id)}
                        onMouseLeave={() => setActiveMenuItemId(null)}
                        target={target}
                      >
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )
          )}
        </div>
        <div className={styles.bgContainer}>
          <button type="button" className={styles.closeBtn} onClick={onClose}>
            Закрыть каталог 
            {' '}
            <i className={styles.closeIcon} />
          </button>
          {menus.map(
            ({ child_items }, i) =>
              child_items &&
              child_items.map(
                ({ object_id, image }) =>
                  image && (
                    <CSSTransition
                      key={object_id}
                      in={object_id === activeMenuItemid}
                      timeout={700}
                      classNames={{
                        enter: styles.enter,
                        enterActive: styles.enterActive,
                        enterDone: styles.enterDone,
                        exit: styles.exit,
                        exitActive: styles.exitActive,
                        exitDone: styles.exitDone,
                      }}
                    >
                      <div className={styles.bgMask}>
                        <div
                          className={styles.bg}
                          style={{
                            backgroundImage: `url('${image.localFile.url}')`,
                          }}
                        />
                      </div>
                    </CSSTransition>
                  )
              )
          )}
        </div>
      </div>
    </div>
  )
}

export default OverlayMenus
