import React from 'react'
import cs from 'classnames'
import style from './BurgerContent.module.scss'

const BurgerContent = ({ active = false, onClose = f => f, children }) => {
  return (
    <div className={cs(style.root, { [style.active]: active })}>
      <div className={style.inner}>{children}</div>
    </div>
  )
}

export default BurgerContent
