import React, { useState, useEffect, useContext } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { useTrail, animated } from 'react-spring'
import cs from 'classnames'
import Link from '../Link'
import style from './Header.module.scss'
import { stripPhone } from '../../util/helpers'
import Burger from '../Burger'
import BurgerContent from '../BurgerContent'
import GlobalContext from '../GlobalContext'
import OverlayMenus from '../OverlayMenus'

const Header = ({ acfOptions }) => (
  <StaticQuery
    query={graphql`
      query {
        wordpressMenusMenusItems(name: { eq: "primary" }) {
          items {
            url
            title
            target
            object_id
            child_items {
              object_id
              title
              url
            }
          }
        }
      }
    `}
    render={({ wordpressMenusMenusItems: menu }) => {
      const { isLoaded } = useContext(GlobalContext)
      const [burgerIsActive, setBurgerIsActive] = useState(false)
      const [menuVisibility, setMenuVisibility] = useState(false)
      const [activeMenuIndex, setActiveMenuIndex] = useState(null)
      const [trail, setTrail] = useTrail(menu.items.length, () => ({
        opacity: 0,
        y: -20,
        config: { mass: 5, tension: 2000, friction: 200 },
      }))

      const toggleBurger = () => {
        setBurgerIsActive(prevState => !prevState)
      }

      const activateBurger = () => {
        setBurgerIsActive(true)
      }

      const deactivateBurger = () => {
        setBurgerIsActive(false)
      }

      useEffect(() => {
        if (!menuVisibility) {
          setMenuVisibility(isLoaded)
        }
      }, [isLoaded])

      useEffect(() => {
        setTrail({
          opacity: menuVisibility ? 1 : 0,
          y: menuVisibility ? 0 : -20,
        })
      }, [menuVisibility])

      return (
        <>
          <header className={style.header}>
            <div className={`${style.container} flex items-stretch`}>
              <Link
                to="/"
                onClick={() => {
                  deactivateBurger()
                  setActiveMenuIndex(null)
                }}
                className={`${style.logo} flex items-center justify-center flex-none border-r border-solid border-gray-200`}
              >
                <img src={acfOptions.logo.url.localFile.url} alt="Logo" />
              </Link>
              <div className="flex-1 lg:hidden" />
              <nav className={`${style.nav} flex-1 px-4 hidden lg:block`}>
                <ul>
                  {trail.map(({ y, opacity }, i) => (
                    <animated.li
                      style={{
                        opacity,
                        transform: y.interpolate(
                          y => `translate3d(0,${y}px,0)`
                        ),
                      }}
                      key={menu.items[i].object_id}
                    >
                      <Link
                        className={cs('primary-link primary-link--alt', {
                          active:
                            i === activeMenuIndex && menu.items[i].child_items,
                        })}
                        onClick={e => {
                          if (menu.items[i].child_items) {
                            e.preventDefault()
                            setActiveMenuIndex(currentState =>
                              currentState === i ? null : i
                            )
                          } else {
                            setActiveMenuIndex(null)
                          }
                        }}
                        // onFocus={() => setActiveMenuIndex(i)}
                        // onBlur={() => setActiveMenuIndex(null)}
                        // onMouseOver={() => setActiveMenuIndex(i)}
                        // onMouseLeave={() => setActiveMenuIndex(null)}
                        to={menu.items[i].url}
                        target={menu.items[i].target}
                      >
                        {menu.items[i].title}
                      </Link>
                    </animated.li>
                  ))}
                </ul>
              </nav>
              <a
                href={`tel:${stripPhone(acfOptions.phones[0].phone)}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`${style.phone} flex items-center justify-center flex-none border-l border-solid border-gray-200 px-5`}
              >
                <div className="flex items-center -mx-2">
                  <div className="px-2">
                    <div className="custom-icon custom-icon--extra-small custom-icon--block custom-icon--phone" />
                  </div>
                  <div className="px-2 text-black tracking-widest font-text hidden sm:block lg:hidden xl:block">
                    {acfOptions.phones[0].phone}
                  </div>
                </div>
              </a>
              <div
                className={`${style.burgerBtn} flex items-center justify-center flex-none border-l border-solid border-gray-200 px-2 lg:hidden`}
              >
                <Burger active={burgerIsActive} onClick={toggleBurger} />
              </div>
            </div>
          </header>
          <OverlayMenus
            menus={menu.items}
            activeMenuIndex={activeMenuIndex}
            onClose={() => setActiveMenuIndex(null)}
          />
          <BurgerContent active={burgerIsActive} onClose={deactivateBurger}>
            <ul
              className={cs(style.burgerMenu, {
                'in-view': burgerIsActive,
              })}
            >
              {menu.items.map((item, i) => (
                <li
                  key={item.object_id}
                  className="primary-appearance"
                  style={{ transitionDelay: `${i * 100 + 300}ms` }}
                >
                  <Link
                    className="primary-link primary-link--alt"
                    to={item.url}
                    onClick={deactivateBurger}
                    target={item.target}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </BurgerContent>
        </>
      )
    }}
  />
)

export default Header
