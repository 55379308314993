// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-pages-projects-js": () => import("../src/templates/pages/projects.js" /* webpackChunkName: "component---src-templates-pages-projects-js" */),
  "component---src-templates-pages-catalog-js": () => import("../src/templates/pages/catalog.js" /* webpackChunkName: "component---src-templates-pages-catalog-js" */),
  "component---src-templates-pages-about-js": () => import("../src/templates/pages/about.js" /* webpackChunkName: "component---src-templates-pages-about-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pages-contacts-js": () => import("../src/templates/pages/contacts.js" /* webpackChunkName: "component---src-templates-pages-contacts-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-project-js": () => import("../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-product-js": () => import("../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-pages-catalog-by-category-js": () => import("../src/templates/pages/catalogByCategory.js" /* webpackChunkName: "component---src-templates-pages-catalog-by-category-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

