import React, { useEffect, useState, useCallback } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
// import EventEmitter from 'eventemitter3'
import GlobalContext from './GlobalContext'
import Header from './Header'
import '../styles/style.scss'
import detectTabbing from '../util/detectTabbing'
import Transition from './Transition'
import LoadingScreen from './LoadingScreen'

const TemplateWrapper = ({ children, location }) => {
  const [globalState, setGlobalState] = useState({
    isLoaded: false,
    isLargeScreen: typeof window !== 'undefined' && window.innerWidth >= 1024,
  })

  const updateScreenState = () => {
    const isLargeWidth = window.innerWidth >= 1024

    setGlobalState(prevState => {
      if (isLargeWidth && !prevState.isLargeScreen) {
        return { ...prevState, isLargeScreen: true }
      }
      if (!isLargeWidth && prevState.isLargeScreen) {
        return { ...prevState, isLargeScreen: false }
      }
      return prevState
    })
  }

  useEffect(() => {
    detectTabbing()
    window.addEventListener('resize', updateScreenState)
    return () => window.removeEventListener('resize', updateScreenState)
  }, [])

  return (
    <GlobalContext.Provider value={globalState}>
      <StaticQuery
        query={graphql`
          query {
            wordpressAcfOptions {
              headless_settings {
                emails {
                  email
                }
                phones {
                  phone
                }
                recipients_emails {
                  email
                }
                footer_text
                logo {
                  url {
                    localFile {
                      url
                    }
                  }
                }
              }
            }
            wordpressSiteMetadata {
              name
              description
            }
          }
        `}
        render={data => {
          const acfOptions = data.wordpressAcfOptions.headless_settings
          const { name, description } = data.wordpressSiteMetadata

          return (
            <>
              <Helmet title={name} description={description} />
              <Header acfOptions={acfOptions} />
              <LoadingScreen
                onAnimationEnd={() =>
                  setGlobalState(prevState => ({
                    ...prevState,
                    isLoaded: true,
                  }))
                }
              />
              <Transition
                location={location}
                onExit={() =>
                  setGlobalState(prevState => ({
                    ...prevState,
                    isLoaded: false,
                  }))
                }
                onEntered={() =>
                  setGlobalState(prevState => ({
                    ...prevState,
                    isLoaded: true,
                  }))
                }
              >
                {children}
              </Transition>
            </>
          )
        }}
      />
    </GlobalContext.Provider>
  )
}

export default TemplateWrapper
