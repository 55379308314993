import React, { useState, useEffect } from 'react'
import cs from 'classnames'
import style from './LoadingScreen.module.scss'

const LoadingScreen = ({ onAnimationEnd = f => f }) => {
  const [animationIsEnded, setAnimationIsEnded] = useState(false)
  const [animationIsStarted, setAnimationIsStarted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimationIsStarted(true)
    }, 50)
    setTimeout(() => {
      setAnimationIsEnded(true)
      onAnimationEnd()
    }, 2300)
  }, [])

  return (
    !animationIsEnded && (
      <div className={cs(style.root, { [style.started]: animationIsStarted })}>
        <div className={style.whiteScreen} />
        <div className={style.logo} />
        <div className={style.lastScreen} />
      </div>
    )
  )
}

export default LoadingScreen
