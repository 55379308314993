import React from 'react'
import cs from 'classnames'
import style from './Burger.module.scss'

const Burger = ({ active = false, onClick }) => {
  const className = cs({
    [style.burger]: true,
    [style.burgerActive]: active,
  })
  return (
    <button type="button" className={className} onClick={onClick}>
      <span className={style.box}>
        <span className={style.line} />
        <span className={style.line} />
        <span className={style.line} />
        <span className={style.line} />
      </span>
    </button>
  )
}

export default Burger
